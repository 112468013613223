<template>
	<div class="Home" style="min-width: 1900px;">
		<el-container>
			<el-header style="background-color: #FFFFFF;">
				<div class="header">
					<img style="width: 250px;" src="../assets/home/homeLogo.png" />

					<div style="display: flex; width: 200px;justify-content: space-between;">
						<!-- <el-button  class="item1" type="primary" @click="getBack">返回</el-button> -->
					</div>
				</div>
			</el-header>
			<el-main>
				<div class="main">
					<div style="display: flex; justify-content: space-between; align-items: center; height: 100px;">
						<div style="font-size: 30px; font-weight: bold; "></div>
						<div style="font-size: 30px; font-weight: bold; margin-left: 300px;">{{list.name}}</div>
						<div style="display: flex; justify-content: flex-end;padding-right: 200px; margin-top: 30px;">

							<el-button v-if="buttonShow" class="item1" type="primary" @click="examine">查看附件</el-button>
							<el-button v-if="buttonShow" class="item1" type="primary" @click="download">下载资料</el-button>
						</div>
					</div>
					<div style="border-top: 1px dotted #2C3E50;margin: 0 500px;padding-top: 50px;">
						<div style="text-align: left;" v-html='content'></div>
						<div v-if="content == null" style="text-align: center;">请查看附件</div>
					</div>
				</div>

			</el-main>
			<el-footer>
				<div class="footer">
					<div style="display: flex; flex-direction: column;">
						<img style="width: 150px;" src="../assets/home/gongzhonghao.jpg" />
						<span style="color: #FFFFFF;">加入智库</span>
					</div>
					<div
						style="display: flex;flex-direction: column; align-items: flex-start; margin-left: 100px; height: 80px;justify-content: space-between;">
						<div class="bottom_flex">
							<img style="width: 30px; margin-right: 20px;" src="../assets/home/danwei.png" />
							<div style="color: #FFFFFF;">主办单位：应急安全智库平台</div>
						</div>
						<div class="bottom_flex">
							<img style="width: 30px; margin-right: 20px;" src="../assets/home/kefu1.png" />
							<div style="color: #FFFFFF;">客服微信：anquan0311</div>
						</div>
					</div>
				</div>
			</el-footer>
		</el-container>
		<el-dialog title="温馨提示" :visible.sync="downExceed" width="30%">
			<span>下载频率过高,将造成网络拥堵，为保障全体会员正常使用资料库，请稍后再试。</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		request
	} from "../network/request.js";
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'Home',
		data() {
			return {
				user_id: localStorage.getItem('user_id'),
				clientWidth: '',
				list: {},
				downExceed: false,
				content: '',
				buttonShow: true
			}
		},
		created() {

			this.clientWidth = document.documentElement.clientWidth
			this.list = this.imgList
			this.list = JSON.parse(window.localStorage.getItem('imgList'))
			request({
				url: 'datum/cultivate/material/detail',
				method: 'post',
				data: {
					id: this.list.id,
					vip: 1
				}
			}).then(res => {
				this.content = res.data[0].content
			})


			request({
				url: 'datum/material/download',
				method: 'post',
				data: {
					warehouse_id: this.list.id
				}
			}).then(res => {
				if (res.code == 1) {
					if (res.data.file == null) {
						this.buttonShow = false
					}
				}
			})
		},
		methods: {
			getBack() {
				window.history.back(-1);
			},
			download() {
				request({
					url: 'datum/material/download',
					method: 'post',
					data: {
						warehouse_id: this.list.id,
						user_id: this.user_id,
					}
				}).then(res => {
					if (res.code == 1) {
						let is_oss = res.data.file.is_oss == 1 ? 1 : 0;
						let suffix = this.getType(res.data.file.paths)
						window.open(
							`http://safety365.shenganyun.cn/v1/api/download?is_oss=${is_oss}&file_path=${res.data.file.paths}&file_name=${this.list.name + suffix}`
						)
						// 	if(res.data.file.is_oss == 1){
						// 		// window.open('http://' + "dunanyun.oss-cn-beijing.aliyuncs.com/" + res.data.file.paths)
						// 	}else if(res.data.file.is_oss != 1){
						// 		// window.open('http://' + "www.shenganyun.cn/" + res.data.file.paths)

						// 	}
					} else if (res.code == -1) {
						this.downExceed = true
					}
				})
			},
			getType(file) {
				var filename = file;
				var index1 = filename.lastIndexOf(".");
				var index2 = filename.length;
				var type = filename.substring(index1, index2);
				return type;
			},
			examine() {
				request({
					url: 'datum/material/download',
					method: 'post',
					data: {
						warehouse_id: this.list.id,
						user_id: this.user_id,
					}
				}).then(res => {
					if (res.code == 1) {
						var suffix = this.getType(res.data.file.paths).toLowerCase()
						if (res.data.file.is_oss == 1) {
							if (suffix == '.doc' || suffix == '.docx' || suffix == '.xlsx' || suffix == '.xls' ||
								suffix == '.ppt' || suffix == '.pptx') {
								window.open('https://view.officeapps.live.com/op/view.aspx?src=' + 'http://' +
									"oss.shenganyun.cn/" + res.data.file.paths)
							} else {
								window.open('http://' + "oss.shenganyun.cn/" + res.data.file.paths)
							}
						} else if (res.data.file.is_oss != 1) {
							if (suffix == '.doc' || suffix == '.docx' || suffix == '.xlsx' || suffix == '.xls' ||
								suffix == '.ppt' || suffix == '.pptx') {
								window.open('https://view.officeapps.live.com/op/view.aspx?src=' + 'http://' +
									"www.shenganyun.cn/" + res.data.file.paths)
							} else {
								window.open('http://' + "www.shenganyun.cn/" + res.data.file.paths)
							}
						}
					} else if (res.code == -1) {
						this.downExceed = true
					}
				})
			}

		},
		computed: {
			...mapState([
				'imgList'
			])
		}

	}
</script>

<style lang="less" scoped>
	.header {
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: space-around;
		border-bottom: 1px solid #2C3E50;

	}

	* {
		padding: 0;
	}


	.footer {
		height: 250px;
		margin-left: -1000px;
		background-color: #033B6B;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.popover_flex {
		display: flex;
		align-items: center;

	}

	.main {
		margin-top: 10px;
		min-height: 615px;
	}

	.item1 {
		width: 100px;
		height: 40px;
	}

	.bottom_flex {
		display: flex;
		align-items: center;
	}
</style>